<template>
  <div
      class="layover"
      :class="fullScreenImg ? 'd-flex' : 'd-none'"
      ref="layover"
      @click="hideFullPageImg"
  >
    <button
        class="rounded-4 border-0 bg-primary px-3 py-1 text-white"
    >
      Clicca ovunque per chiudere
    </button>
    <div class="position-relative w-100 d-flex justify-content-center px-3">
      <img :src="srcs[indexImg]" alt="" ref="layoverImg"/>
      <div class="next" @click.stop="next()">
        <font-awesome-icon
          icon="fa-solid fa-chevron-right"
        />
      </div>
      <div class="back" @click.stop="back()">
        <font-awesome-icon
          icon="fa-solid fa-chevron-left"
        />
      </div>
    </div>
    <router-link
        class="link-warning fw-bold"
        :to="{ name: 'contact-me' }"
    >
      <button
          class="rounded-4 border-0 bg-primary px-3 py-3 text-white"
      >
        Ti piace questa foto? <span class="text-warning text-decoration-underline">Contattami</span>
        per richiedere la versione digitale non compressa o una stampa!
      </button>
    </router-link>
  </div>
</template>

<script>

  export default {
    props: {
      fullScreenImg: Boolean,
      indexImg: Number,
      srcs: Array,
    },
    data() {
      return {
        nextIndex: -1,
    }
    },
    methods: {
      hideFullPageImg() {
        // reset layover
        this.nextIndex = -1;
        this.$refs.layoverImg.src = this.srcs[this.indexImg];
        // emit evento per chiudere layover
        this.$emit('custom-event');
      },
      next() {
        if (this.nextIndex === -1) {
          this.nextIndex =
              (this.indexImg === this.srcs.length - 1)? 0 : this.indexImg + 1;
        } else {
          this.nextIndex =
              (this.nextIndex === this.srcs.length - 1)? 0 : this.nextIndex + 1;
        }
        this.$refs.layoverImg.src = this.srcs[this.nextIndex];
      },
      back() {
        if (this.nextIndex === -1) {
          this.nextIndex =
              (this.indexImg === 0) ? this.srcs.length - 1 : this.indexImg - 1;
        } else {
          this.nextIndex =
              (this.nextIndex === 0)? this.srcs.length - 1 : this.nextIndex - 1;
        }
        console.log(this.nextIndex)
        this.$refs.layoverImg.src = this.srcs[this.nextIndex];
      },
    }
  }

</script>

<style lang="scss">
.layover {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 100000;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;

  & img {
    max-height: 80vh;
  }
}

.next, .back {
  position: absolute;
  top: 50%;
  font-size: 3rem;
  color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border-radius: 0.25rem;

  &:hover {
    background-color: #000000;
  }
}

.next {
  right: 1rem;
}

.back {
  left: 1rem;
}
</style>