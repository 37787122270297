<template>
  <main>
    <section class="carousel-container">
      <div class="p-1 p-sm-3 p-md-4 p-lg-5" >
        <div class="container-fluid my_shadow p-4 p-lg-5" id="grid_container">
          <h3 class="mb-5">
            {{ category.name }}
          </h3>
          <blockquote
              class="text-white text-center"
              v-if="category.cit && category.aut"
          >
            <q class="d-block mb-2">
              {{ category.cit }}
            </q>
            <cite>
              {{ category.aut }}
            </cite>
          </blockquote>
          <grid-component class="mb-5" :srcs="srcsToView" :category="category"/>
          <div
              class="btn btn-primary d-flex justify-content-center"
              @click="addImgs"
              v-if="index < src.length"
          >
            MOSTRA ALTRE FOTO
          </div>
        </div>
      </div>
      <div v-if="!src.length || loading">
        <LoadingScreen/>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import LoadingScreen from "@/components/LoadingScreen.vue";
import GridComponent from "@/components/GridComponent";
import { GET_PICS_FROM_TABLE_API, AMBIENTE, PC } from "@/config";

export default {
  components: {
    GridComponent,
    LoadingScreen,
  },
  props: {
    category: Object,
  },
  data() {
    return {
      loading: false,
      src: [],
      moveCount: 0,
      fullScreenImg: false,
      index: 0,
      srcsToView: [],
      imgPerGrid: 12,
    };
  },
  methods: {
    scroll() {
      window.scrollTo({
        top: window.innerHeight,
        behavior: "smooth",
      });
    },
    showImg() {
      this.$refs.layoverImg.src = this.src[this.index];
      this.fullScreenImg = true;
    },
    addImgs() {
      this.loading = true;
      const thisLoopIndex = this.index
      for (let i = this.index; i < thisLoopIndex + this.imgPerGrid; i++) {
        if (i > this.src.length - 1) {
          break;
        } else {
          this.srcsToView.push(this.src[i])
          this.index++;
        }
      }
      // console.log("D3");
      setTimeout(() => {
        this.loading = false;
      }, 500)
    },
    fetchSrc() {
      axios
          .get(
              `${GET_PICS_FROM_TABLE_API}?table=${this.category.db_table}`
          )
          .then((res) => {
            this.src = [];
            // console.log(res.data);
            // console.log(this.category.db_table);
            res.data.forEach((element) => {
              try {
                let toPush;
                if (AMBIENTE === 'DEV') {
                  if (PC === 'VOLA'){
                    toPush =
                      require("C:/Users/Francesco Pieraccini/Documents/dev/control-panel/control-panel/assets/" +
                          this.category.dir +
                          element);
                  }
                  if (PC === 'HOME') {
                    toPush =
                      require("C:/Users/franc/Documents/dev/control-panel/assets/" +
                          this.category.dir +
                          element);
                  }
                } else {
                  toPush = "../assets/" + this.category.dir + element;
                }

                this.src.push(toPush);
              } catch (e) {
                // console.error(e);
                console.error('Immagine: ' + element + ' non presente nel filesystem');
              }
            });
            if (this.src.length === 0) {
              const toPush =
                  (AMBIENTE === 'DEV') ?
                      require("../../../assets/wip.gif") :
                      "../assets/wip.gif";
              this.src.push(toPush);
            }
            for (let i = 0; i < this.imgPerGrid; i++) {
              if (i === this.src.length) {
                return;
              } else {
                this.srcsToView.push(this.src[i])
                this.index++;
              }
            }
          })
          .catch((error) => {
            // Gestione degli errori
            if (error.response) {
              // Errore dalla risposta del server (status code diverso da 2xx)
              console.log(
                  "Errore dalla risposta del server:",
                  error.response.data
              );
              console.log("Codice di stato:", error.response.status);
            } else if (error.request) {
              // Nessuna risposta ricevuta dal server
              console.log("Nessuna risposta ricevuta dal server");
            } else {
              // Errore durante la configurazione della richiesta
              console.log(
                  "Errore durante la configurazione della richiesta:",
                  error.message
              );
            }

            // in ogni caso mostro immagine del WIP
            const toPush =
                (AMBIENTE === 'DEV') ?
                    require("../../../assets/wip.gif") :
                    "../assets/wip.gif";
            this.src.push(toPush);
          });
    },
    checkIsImg(path) {
      return !path.includes(".mp4");
    },
  },
  mounted() {
    this.fetchSrc();
  },
  watch: {
    $route() {
      this.src = [];
      this.srcsToView = [];
      this.fetchSrc();
      document.getElementById("grid_container").scrollIntoView();
      this.index = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.carousel-container {
  background-color: $--dark-grey;
  padding: 4rem 1rem;

  @media (min-width: 576px) {
    padding: 4rem 0;
  }

  h3 {
    color: #fff;
    text-align: center;
    margin-bottom: 2rem;
  }
}

.container-fluid {
  background-color: $--grey;
  border-radius: 2rem;
}

</style>