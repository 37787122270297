<template>
  <main>
    <section class="about">
      <div class="container">
        <div class="txt-container my_shadow">

          <h3 class="mb-5 text-center">Le immagini secondo me...</h3>

          <p>
            Fotografare è la mia passione...<br/>
            Sono nato a Viareggio nel 1966, ho sempre avuto un forte interesse per
            la fotografia e per tutto ciò che tratta tale argomento, seguo con
            attenzione e curiosità ogni sua evoluzione.<br/>
            Da ragazzo tenevo sempre con me una Polaroid istantanea,
            mi divertiva scattare foto ed avere la possibilità di vederle subito, in
            seguito una piccola fotocamera a pellicola di marca Rollei sottratta a mio
            padre ha aperto in me un mondo nuovo, conservo tuttora con molta cura
            questo mio piccolo tesoro.<br/>
            Quando fotografo immagino già nella mia
            mente la scena, studio le varie prospettive e cerco di creare più che un
            semplice scatto, un momento di vita da poter ricordare sempre con
            soddisfazione ed allegria. Sono convinto che agendo con il cuore ogni
            immagine scattata viva per sempre.
          </p>

        </div>
      </div>
    </section>
  </main>
</template>

<script>

export default {
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.about {
  background-color: $--dark-grey;
  color: #fff;
  padding: 4rem 0;

  .txt-container {
    background-color: $--grey;
    padding: 2rem;
    border-radius: 2rem;

    @media (min-width: 576px) {
      font-size: 1.25rem;
    }
  }
}
</style>