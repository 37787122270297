<template>
  <div class="position-relative">
    <div class="grid grid-template">
      <div
          class="img_frame my_shadow"
          @contextmenu.prevent
          v-for="(src, i) in srcs"
          :key="i"
      >
        <div class="justify-content-center align-items-center d-flex">
          <img
              v-if="checkIsImg(src)"
              :src="src"
              :alt="`${category.name}: Foto n. ${i}`"
              @click="showImg(i)"
              :id="'img_' + i"
          />
        </div>
      </div>
    </div>
    <full-page-img-layover
        :fullScreenImg="fullScreenImg"
        :indexImg="indexImg"
        :srcs="srcs"
        @custom-event="handleHideFullPageImg"
    />
  </div>
</template>

<script>
import FullPageImgLayover from "@/components/FullPageImgLayover";
export default {
  components: {
    FullPageImgLayover
  },
  props: {
    srcs: Array,
    category: Object,
  },
  data() {
    return {
      moveCount: 0,
      fullScreenImg: false,
      indexImg: -1
    };
  },
  methods: {
    showImg(index) {
      this.indexImg = index;
      this.fullScreenImg = true;
    },
    checkIsImg(path) {
      return !path.includes(".mp4");
    },
    handleHideFullPageImg() {
      this.fullScreenImg = false;
    }
  },
};
</script>