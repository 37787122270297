<!-- Pulsante per tornare in testa alla pagina -->

<template>
    <div class="scroll-btn" ref="btn" @click="goTop">
        <font-awesome-icon icon="fa-solid fa-chevron-up" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            scrollTimer: 0,
            scrollY: 0,
        }
    },
    methods: {
        scrollHandler() {
          // console.log(this.$refs.btn.style.display)
            if (window.scrollY < 500 || window.innerWidth < 992) {
                this.$refs.btn.style.display = 'none';
            }
            if (window.scrollY >= 500 && window.innerWidth >= 992) {
                this.$refs.btn.style.display = 'block';
            }
            if (this.scrollTimer) return;
            this.scrollTimer = setTimeout(() => {
                this.scrollY = window.scrollY;
                clearTimeout(this.scrollTimer);
                this.scrollTimer = 0;
            }, 100);
        },
        goTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('scroll', this.scrollHandler);
        })
    },
}
</script>

<style lang="scss" scoped>
.scroll-btn {
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 0.75rem;
    border-radius: 0.5rem;
    display: none;
    color: #000000;
    font-size: 0.75rem;
    cursor: pointer;
    z-index: 9999;

  //@media (min-width: 768px) {
  //  display: block;
  //}
}
</style>