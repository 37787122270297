<template>
  <main>
    <section class="container">

      <h3 class="text-white text-center mb-5">
        Modulo di contatto
      </h3>

      <div class="form-container">

        <form ref="form" @submit.prevent>

          <input v-model="name" type="text" name="user_name" placeholder="Il tuo nome *" required class="my_shadow">
                    <input v-model="email" type="email" name="user_email" placeholder="La tua email *" required class="my_shadow">
                    <textarea v-model="message" name="message" id="" placeholder="Il tuo messaggio *" required class="my_shadow"></textarea>
          <div class="btn-wrap">
            <b-button @click="sendEmailHandler" type="submit" variant="primary"
                      :class="[disable ? 'bg-dark border-dark' : '', 'me-3 my_shadow']" ref="submit">
              {{ disable ? 'Invio in corso...' : 'Invia' }}
            </b-button>
            <b-button type="reset" variant="danger" :class="[disable ? 'bg-dark border-dark' : '', 'my_shadow']"
                      ref="reset">Cancella
            </b-button>
          </div>

        </form>

      </div>
    </section>
  </main>

  </template>
  
  <script>
  import emailjs from '@emailjs/browser';

  export default {
    data() {
      return {
          email: '',
          name: '',
          message: '',
          disable: false
      }
    },
    methods: {
      sendEmail() {
        emailjs.sendForm('service_56u0gnl', 'template_lslv8m7', this.$refs.form, 'uiC6ZLDWYl9_4RuBt')
          .then(() => {
                alert('Messaggio inviato con successo!');
                this.email = '';
                this.name = '';
                this.message = '';
                this.disable = false;
                this.$refs.submit.disabled = false;
                this.$refs.reset.disabled = false;
          }, (error) => {
                alert('Qualcosa è andato storto. Ricarica la pagina per riprovare...', error.text);
                this.email = '';
                this.name = '';
                this.message = '';
          });
      },
      sendEmailHandler() {
        // console.log(this.$refs.submit.disabled);
        this.$refs.submit.disabled = true;
        this.$refs.reset.disabled = true;
        this.disable = true;
        this.sendEmail();
      }
    }
  }
  </script>

<style lang="scss" scoped>

@import '@/style/variables';

main {
  background-color: $--dark-grey;
  padding: 5rem 0;
  color: #fff;

  .form-container {
    background-color: white;
    padding: 2rem;
    border-radius: 2rem;
    background-color: $--grey;
  }

  form {
    display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        input, textarea {
            width: 100%;
            border: 1px solid #3d3d3d;
            border-radius: 0.5rem;
            // box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
            background-color: $--light-grey;
            color: #FFF;
            margin-bottom: 2rem;
            padding: 2rem;

            @media (min-width:768px) {
                // width: 40rem;
            }
        }

        input {
            height: 4rem;
            display: block;

            &:focus-visible{
                outline: none;
            }
        }

        textarea {
            height: 10rem;

            &:focus-visible{
                outline: none;
            }
        }
    }
}

</style>