var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"container-fluid py-3",attrs:{"id":"footer-social"}},[_c('div',{staticClass:"socials"},[_c('div',{staticClass:"pb-4"},[_c('h3',{staticClass:"text-white"},[_vm._v("Passa a trovarmi sui miei Social")]),_c('div',{staticClass:"d-flex justify-content-center gap-3"},[_c('a',{staticClass:"socials-btn fb",attrs:{"href":"https://www.facebook.com/roberto.pieraccini.52"}},[_c('font-awesome-icon',{staticClass:"txt-big",attrs:{"icon":"fa-brands fa-facebook-f"}})],1),_c('a',{staticClass:"socials-btn ig",attrs:{"href":"https://www.instagram.com/robixpier/"}},[_c('font-awesome-icon',{staticClass:"txt-big",attrs:{"icon":"fa-brands fa-instagram"}})],1)])]),_c('div',[_c('h3',{staticClass:"text-white"},[_vm._v(" Inviami un'email al mio indirizzo di posta ")]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('a',{staticClass:"socials-btn mail",attrs:{"href":"mailto:roberto.pieraccini@gmail.com","target":"_blank"}},[_c('font-awesome-icon',{staticClass:"txt-big",attrs:{"icon":"fa-regular fa-envelope"}})],1)])])])]),_c('div',{staticClass:"container-fluid",attrs:{"id":"footer-main"}},[_c('div',{staticClass:"d-flex flex-column flex-md-row justify-content-md-center text-center gap-3 gap-md-0"},[_c('ul',{staticClass:"ps-0 mb-0 pe-md-4"},[_vm._m(0),_c('li',[_c('router-link',{attrs:{"to":{ name: 'home' }}},[_vm._v(" Home")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'about-me' }}},[_vm._v(" Su di me ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: 'contact-me' }}},[_vm._v(" Contattami ")])],1)]),_c('ul',{staticClass:"ps-0 ps-md-4 border-start-md"},[_vm._m(1),_vm._l((_vm.categories),function(category,i){return _c('li',{key:i},[_c('router-link',{staticClass:"drop-item",attrs:{"to":{
                                name: 'categories.show',
                                params: {
                                    name: category.name.toLowerCase().split(' ').join('_'),
                                },
                            }}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)})],2)]),_vm._m(2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h4',[_vm._v("Pagine principali")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('h4',[_vm._v("Foto per categoria")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center justify-content-center",attrs:{"id":"copy"}},[_vm._v(" Copyright © 2022-2024 "),_c('a',{staticClass:"d-flex flex-column align-items-center",attrs:{"href":"https://www.francescopieraccini.it/"}},[_c('img',{staticClass:"block",attrs:{"src":"https://www.francescopieraccini.it/my_site/src/img/loghi/light-blue.svg","alt":"Logo"}}),_c('small',{attrs:{"id":"nome_logo"}},[_vm._v("Francesco Pieraccini")])]),_vm._v(" . All Rights Reserved ")])
}]

export { render, staticRenderFns }