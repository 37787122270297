<!-- Pulsante per tornare in testa alla pagina -->

<template>
    <div class="socials">
        <a
            href="https://www.facebook.com/roberto.pieraccini.52"
            class="socials-btn fb"
        >
            <font-awesome-icon icon="fa-brands fa-facebook-f" class="txt-big" />
        </a>
        <a href="https://www.instagram.com/robixpier/" class="socials-btn ig">
            <font-awesome-icon icon="fa-brands fa-instagram" class="txt-big" />
        </a>
        <a
            href="mailto:roberto.pieraccini@gmail.com"
            class="socials-btn mail"
            target="_blank"
        >
            <font-awesome-icon icon="fa-regular fa-envelope" class="txt-big" />
        </a>
    </div>
</template>
  
  <script>
    export default {};
</script>
  
  <style lang="scss" scoped>
    .socials {
        display: none;
        position: fixed;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.3);

        @media (min-width: 992px) {
            display: block;
        }

        .socials-btn {
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            z-index: 9999;
            color: #ffffff;

            &:hover {
                scale: 1.2;
            }

            .txt-big {
                font-size: 2rem;
            }
        }
    }
</style>