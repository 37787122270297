<template>
    <div id="categories">
        <JumbotronVue />

        <MainHeader>
          <div class="container-md">

            <h2 class="text-uppercase mb-sm-5">
              galleria
            </h2>
            <p>
              Alcune delle mie foto suddivise per categorie. <br>
              Le foto sono compresse e in formato web, se desideri scaricare
              un'immagine con la sua reale qualità ti invito a contattarmi
              tramite l'apposita sezione. <br>
              Clicca su una foto se desideri vederla a tutta pagina.
            </p>

          </div>
        </MainHeader>

        <div v-if="category">
            <MainContent :category="category" />
        </div>

        <div v-else>
            <LoadingScreen />
        </div>
    </div>
</template>
  
<script>
    import JumbotronVue from "@/components/JumbotronLayout.vue";
    import MainHeader from "@/components/MainHeaderLayout.vue";
    import MainContent from "./components/MainContent.vue";
    import LoadingScreen from "@/components/LoadingScreen.vue";
    import axios from "axios";
    import { GET_CATEGORIES_API } from "@/config";

    export default {
        props: ["name"],
        components: {
            JumbotronVue,
            MainHeader,
            MainContent,
            LoadingScreen,
        },
        data() {
            return {
                categories: [],
            };
        },
        computed: {
            category() {
                let category;
                this.categories.forEach((element) => {
                    if (element["name"].toLowerCase() === this.name.split("_").join(' ')) {
                        category = element;
                    }
                });
                // console.log(category);
                return category;
            },
        },
        methods: {
            fetchCategories() {
                axios
                    .get(
                        GET_CATEGORIES_API
                    )
                    .then((res) => {
                        try {
                            this.categories = [];
                            // console.log(res);
                            res.data.forEach((element) => {
                                this.categories.push(element);
                                // console.log(this.categories);
                            });

                            // controllo se la categoria in element esiste
                            let isCategory = false;
                            this.categories.forEach((element) => {
                                if (element["name"].toLowerCase() === this.name.split("_").join(' ')) {
                                    isCategory = true;
                                }
                            });
                            // se non esiste rimando alla home
                            if (!isCategory) {
                                this.$router.push("/");
                            }
                        } catch (e) {
                            console.error(e);
                        }
                    });
            },
        },
        metaInfo() {
            return {
                meta: [
                    {
                        name: "description",
                        content: "Galleria delle foto suddivise per categorie",
                    },
                ],
            };
        },
        mounted() {
            this.fetchCategories();
        },
    };
</script>
  
<style lang="scss" scoped>
  #categories .jumbotron {
    background-image: url('@/assets/gallery-jumbo.jpeg');
    background-position: bottom;
  }
</style>