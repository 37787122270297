<template>
  <div @click="scroll" class="scroll-down">
    <img
        src="../assets/scroll-wheel-2.webp"
        class="w-16"
        alt="Scroll Down"
    />
  </div>
</template>

<script>
export default {
    methods: {
        scroll() {
            window.scrollTo({
                top: window.innerHeight,
                behavior: "smooth"
            });
        }
    },
}
</script>

<style lang="scss" scoped>

.scroll-down {
  position: absolute;
  top: 90vh;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 5rem;
  cursor: pointer;
}

</style>