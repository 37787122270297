<template>
    <div class="layoverLoading">
        <img :src="require('../assets/loading-gif.gif')" alt="" />
    </div>
</template>

<script>
    export default {};
</script>

<style lang="scss" scoped>
    .layoverLoading {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba($color: #000000, $alpha: 0.9);
        z-index: 999999;

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>