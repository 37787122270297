import Home from '../pages/index/App.vue'
import AboutMe from '../pages/aboutMe/App.vue'
import ContactMe from '../pages/contact/App.vue'
import CategoriesShow from '../pages/categories/CategoriesShow.vue'
import LuccaComics2023 from '../pages/luccacomics2023/App.vue'


const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            title: 'Roberto Pieraccini PH - Homepage'
        }
    },
    {
        path: '/contattami',
        name: 'contact-me',
        component: ContactMe,
        meta: {
            title: 'Roberto Pieraccini PH - Contattami',
            metaTags: [
                {
                    name: 'description',
                    content: 'Hai domande o richieste da fare? Ecco il modulo di contatto'
                }
            ]
        }
    },
    {
        path: '/su-di-me',
        name: 'about-me',
        component: AboutMe,
        meta: {
            title: 'Roberto Pieraccini PH - Su di Me'
        }
    },
    {
        path: '/categorie/:name',
        name: 'categories.show',
        component: CategoriesShow,
        props: true,
        meta: {
            title: 'Roberto Pieraccini PH - Galleria'
        }
    },
    {
        path: '/luccacomics2023',
        name: 'luccacomics2023',
        component: LuccaComics2023,
        // props: true,
        meta: {
            title: 'Lucca Comics And Games 2023',
            metaTags: [
                {
                    property: 'og:image',
                    content: '<%= BASE_URL %>lucca-comics-2023.jpeg', // Update path if necessary
                },
                {
                    property: 'og:site_name',
                    content: 'Lucca Comics And Games 2023', // Update path if necessary
                },
                {
                    property: 'og:title',
                    content: 'Lucca Comics And Games 2023', // Update path if necessary
                },
                {
                    property: 'og:url',
                    content: "https://www.robertopieraccini.it/luccacomics2023",
                },
                {
                    property: 'og:image:width',
                    content: "1280",
                },
                {
                    property: 'og:image:height',
                    content: "720",
                },
                {
                    property: 'og:image:alt',
                    content: "Lucca Comics 2023 poster",
                },
            ],
        }
    },
    // Rotta di fallback per gestire percorsi non validi
    {
        path: '*',
        redirect: '/'
    }
];

export default routes;