<template>
  <div id="app">

    <Jumbotron />

    <MainHeaderVue>
      <div class="container-md">

        <h2 class="text-uppercase mb-sm-5">
          welcome to my life
        </h2>
        <blockquote class="text-center">
          <q>
            Fotografare è la mia passione... <br>
            Sono convinto che agendo con il cuore ogni immagine scattata viva per sempre.
          </q>
        </blockquote>
      </div>
    </MainHeaderVue>

    <MainContentVue />

  </div>
</template>

<script>
import Jumbotron from '@/components/JumbotronLayout.vue'
import MainHeaderVue from '@/components/MainHeaderLayout.vue';
import MainContentVue from './components/MainContent.vue';

export default {
  name: 'App',
  components: {
    Jumbotron,
    MainHeaderVue,
    MainContentVue,
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: 'Pagina personale di fotografia di Roberto Pieraccini'
        }
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
#app .jumbotron {
  background-image: url('@/assets/rp-jumbo.jpg');
  background-position: right;
}
</style>
