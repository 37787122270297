export const GET_CATEGORIES_API =
    (process.env.NODE_ENV === 'production') ?
        `https://www.robertopieraccini.it/control-panel/apis/getCategories.php` :
        `http://control-panel.localhost/apis/getCategories.php`;
export const GET_PICS_FROM_TABLE_API =
    (process.env.NODE_ENV === 'production') ?
        'https://www.robertopieraccini.it/control-panel/apis/getPicsFromTable.php' :
        'http://control-panel.localhost/apis/getPicsFromTable.php';
export const INCREMENT_VISIT_API =
    (process.env.NODE_ENV === 'production') ?
        'https://www.robertopieraccini.it/control-panel/apis/handleVisitCounter.php' :
        'http://control-panel.localhost/apis/handleVisitCounter.php';
export const AMBIENTE =
    (process.env.NODE_ENV === 'production') ?
        "PROD" : "DEV";

// TODO
export const PC = 'HOME';
// export const PC = 'VOLA';