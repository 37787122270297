<template>
  <div class="jumbotron">
    <div class="layover"></div>
  </div>
</template>

<script>

export default {
  name: 'MainJumbo',
  components: {
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import '@/style/variables';

  .jumbotron {
    z-index: -1;
    background-color: $--black;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-size: cover;
    background-position: top;

    .layover {
      position: absolute;
      background-color: hsla(0, 0, 0, 0.6);
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

</style>
