<template>
    <div id="luccacomics2023">
        <JumbotronVue />

        <MainHeader>
            <div class="container-md">
                <h2 class="text-uppercase pb-3">
                    Lucca comics and games 2023
                </h2>

                <p class="text-center text-uppercase" id="subtitle">
                    CIAO A TUTTI E BENVENUTI NELLA MIA PAGINA DEDICATA
                    ALL'EVENTO LUCCA COMICS AND GAMES 2023! <br />
                    SONO ENTUSIASTA DI CONDIVIDERE CON VOI UN'ANTEPRIMA
                    DEL MIO COINVOLGIMENTO NELL'EVENTO DI QUEST'ANNO.
                    RIMANETE SINTONIZZATI PER SCOPRIRE COME POTRETE
                    INCONTRARMI, CATTURARE MOMENTI SPECIALI INSIEME E
                    RIMANERE AGGIORNATI SULLE ULTIME NOVITÀ.
                </p>
            </div>
        </MainHeader>

        <MainContent />
    </div>
</template>

<script>
    import JumbotronVue from "@/components/JumbotronLayout.vue";
    import MainHeader from "@/components/MainHeaderLayout.vue";
    import MainContent from "./components/MainContent.vue";

    export default {
        components: {
            JumbotronVue,
            MainHeader,
            MainContent,
        },
        metaInfo() {
            return {
                meta: [
                    {
                        name: "description",
                        content:
                            "Pagina dedicata all'evento Lucca Comics and Games 2023",
                    },
                ],
            };
        },
    };
</script>

<style lang="scss" scoped>
    #luccacomics2023 .jumbotron {
        background-image: url("@/assets/luccacomics2023/lucca-comics-2023.jpeg");
        background-position: 20%;
    }
</style>