<template>
    <header>
        <div class="body" ref="content">
          <slot />
        </div>
        <ScrollDownBtn />
    </header>
</template>
  
<script>

    import ScrollDownBtn from '@/components/ScrollDownBtn.vue'

    export default {
        components: {
            ScrollDownBtn
        },
        created() {
            setTimeout(() => {
                    this.$refs.content.style.opacity = 1;
                }, 100)
        }
    }
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
header {
  display: flex;
  flex-direction: column;
  color: #000000;
  height: 100vh;

  @media (min-width:576px) {
  }

  .body {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 1s ease-in-out;
    color: #FFF;

    .container-md {
      justify-content: flex-end;
      align-items: flex-end;
      height: auto;
      display: block;
    }

    h2 {
      text-align: center;
      font-size: 2rem;
      font-family: "Cormorant SC", serif;
      padding-top: 3rem;

      @media (min-width:768px) {
        font-size: 4rem;
      }
    }

    p {
      text-align: center;
      display: block;
      font-size: 0.75rem;
      margin-bottom: 3rem;

      &#subtitle {
        font-size: 1rem;
      }

      @media (min-width:576px) {
        font-size: 1.25rem;
      }
    }
  }
}
</style>
