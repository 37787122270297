<template>
  <main>
    <section>
      <div class="container">
        <div class="text-center mb-5">
          <h3 class="mb-5">Qualcosa da scoprire...</h3>
          <blockquote>
            <q class="d-block">
              Di sicuro, ci sarà sempre chi guarderà solo la
              tecnica e si chiederà “come”, mentre altri di natura
              più curiosa si chiederanno “perché”
            </q>
            <cite> Man Ray </cite>
          </blockquote>
        </div>
      </div>
    </section>

    <section>
      <div class="container">
        <div class="grid grid-3">
          <!-- LUCCA COMICS 2023 - SEZIONE SPECIALE -->
          <!--                  <router-link-->
          <!--                      :to="{-->
          <!--                            name: 'luccacomics2023',-->
          <!--                        }"-->
          <!--                      class="col-grid my_shadow col-span-full"-->
          <!--                  >-->
          <!--                    <div class="img-wrapper">-->
          <!--                      <img-->
          <!--                          @contextmenu.prevent-->
          <!--                          :src="require('../../../assets/luccacomics2023/lucca-comics-2023.jpeg')"-->
          <!--                          alt="Poster"-->
          <!--                      />-->
          <!--                    </div>-->
          <!--                    <div class="category">-->
          <!--                      Lucca Comics And Games 2023-->
          <!--                    </div>-->
          <!--                  </router-link>-->
          <!-- /FINE/ LUCCA COMICS 2023-->
          <router-link
              :to="{
                            name: 'categories.show',
                            params: { name: category.name.toLowerCase().split(' ').join('_') },
                        }"
              class="col-grid my_shadow"
              v-for="(category, i) in categories"
              :key="i"
          >
            <div class="img-wrapper">
              <img
                  @contextmenu.prevent
                  :src="category.poster"
                  :alt="'Poster ' + category.name"
              />
            </div>
            <div class="category">
              {{ category.name }}
            </div>
          </router-link>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";
import {AMBIENTE, PC, GET_CATEGORIES_API} from '@/config.js';

export default {
  data() {
    return {
      categories: [],
    };
  },
  methods: {
    fetchCategories() {
      axios
          .get(
              GET_CATEGORIES_API
          )
          .then((res) => {
            this.categories = [];
            res.data.forEach((element) => {
              try {
                element.poster = (AMBIENTE === 'DEV') ?
                  (
                    (PC === 'VOLA') ?
                      require("C:/Users/Francesco Pieraccini/Documents/dev/control-panel/control-panel/assets/" +
                      element.dir +
                      element.poster) :
                      require("C:/Users/franc/Documents/dev/control-panel/assets/" +
                      element.dir +
                      element.poster)
                  ) :
                    "../assets/" + element.dir + element.poster;
                this.categories.push(element);
              } catch (e) {
                console.error(e);
                console.log('Categoria in errore');
                console.log(element);
              }
            });
          });
    },
  },
  mounted() {
    this.fetchCategories();
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

main {
  background-color: $--dark-grey;
  padding: 5rem 0;
  color: #fff;
}

.grid-3 {
  gap: 2rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  a {
    text-decoration: none;
  }

  .col-grid {
    border-radius: 1rem;
    overflow: hidden;

    &.col-span-full {
      @media (min-width: 768px) {
        grid-column: span 2;
      }

      @media (min-width: 1200px) {
        grid-column: span 3;
      }

      img {
        object-position: top left;
      }
    }

    & > * {
      cursor: pointer;
    }

    .img-wrapper {
      overflow: hidden;
      position: relative;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }

    .category {
      background-color: $--grey;
      padding: 1rem;
      text-align: center;
      text-transform: uppercase;
      color: #d1d1d1;
    }

    img {
      display: block;
      width: 100%;
      object-fit: cover;
      transition: 500ms ease-in-out;
      height: 400px;

      @media (min-width: 576px) {
        height: 500px;
      }
    }

    &:hover img {
      scale: 1.1;
    }

    &:hover .category {
      color: #ffffff;
      background-color: $--light-grey;
      font-weight: bold;
    }
  }
}
</style>