<template>
    <div ref="app" @load="reload">
        <MainNav />
        <router-view></router-view>
        <MainFooter />
        <MySocials
            v-motion
            :initial="{ opacity: 0, x: -100 }"
            :enter="{ opacity: 1, x: 0 }"
            :delay="250"
            :duration="1000"
        />
      <ScrollTopVue />

    </div>
</template>

<script>
    import MainNav from "./components/MainNav.vue";
    import MySocials from "./components/MySocials.vue";
    import MainFooter from "./components/MainFooter.vue";
    import ScrollTopVue from "./components/ScrollTop.vue";
    import axios from "axios";
    import {INCREMENT_VISIT_API, AMBIENTE} from "@/config";

    export default {
        components: {
            MainNav,
            MySocials,
            MainFooter,
          ScrollTopVue,
        },
        data() {
            return {
                actualParam: this.$route.params.id,
            };
        },
        watch: {
            $route() {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            },
        },
        methods: {
            reload() {
                window.scrollTo({
                    top: 0,
                });
            },
          incrementVisit() {
            axios
                .get(
                    INCREMENT_VISIT_API
                )
                .catch((e) => {
                  console.error(e);
                })
            ;
          },
        },
        mounted() {
            this.reload();
            if (AMBIENTE !== 'DEV') {
              this.incrementVisit();
            }
        },
    };
</script>

<style lang="scss">
    @import "@/style/general";
    @import "@/style/variables";
</style>