<template>
  <div id="about-me">

    <JumbotronVue />

    <MainHeaderLayout>
      <div class="container-md">

        <h2 class="text-uppercase">
          roberto pieraccini
        </h2>

      </div>
    </MainHeaderLayout>

    <MainContent />

  </div>

</template>

<script>
import JumbotronVue from '@/components/JumbotronLayout.vue';
import MainContent from './components/MainContent.vue';
import MainHeaderLayout from "@/components/MainHeaderLayout";

export default {
  components: {
    MainHeaderLayout,
    JumbotronVue,
    MainContent,
  },
  metaInfo() {
    return {
      meta: [
        {
          name: 'description',
          content: 'Alcune informazioni personali su Roberto Pieraccini'
        }
      ]
    }
  }
}

</script>

<style lang="scss" scoped>
#about-me .jumbotron {
  background-image: url('@/assets/rp-about.jpeg');
  background-position: 20%;
}
</style>