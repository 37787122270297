<template>
    <div class="header">
        <h1>
            <router-link :to="{ name: 'home' }" class="logo">
                Roberto Pieraccini PH
            </router-link>
        </h1>

        <nav>
            <div class="burger" @click="reveal">
                <font-awesome-icon icon="fa-solid fa-bars" />
            </div>
            <div
                class="layover"
                @click="reveal"
                ref="layover"
                :class="nav ? 'animation' : ''"
            >
                <ul class="mb-0">
                    <li>
                        <router-link
                            class="list-element"
                            :to="{ name: 'home' }"
                        >
                            Home
                        </router-link>
                    </li>

<!--                    <li>-->
<!--                        <router-link-->
<!--                            class="list-element"-->
<!--                            :to="{ name: 'luccacomics2023' }"-->
<!--                        >-->
<!--                            Lucca Comics and Games 2023-->
<!--                        </router-link>-->
<!--                    </li>-->

                    <li
                        class="list-element dropdown"
                        @click.stop="drop = !drop"
                        ref="gallery"
                    >
                        Galleria
                        <font-awesome-icon
                            icon="fa-solid fa-caret-down"
                            class="ms-1"
                            v-if="!drop"
                        />
                        <font-awesome-icon
                            icon="fa-solid fa-caret-up"
                            class="ms-1"
                            v-else
                        />
                        <div
                            class="drop-items"
                            :class="drop ? 'd-flex' : 'd-none'"
                            @click="reveal"
                        >
                            <router-link
                                class="drop-item"
                                :to="{
                                    name: 'categories.show',
                                    params: {
                                        name: category.name.toLowerCase().split(' ').join('_'),
                                    },
                                }"
                                v-for="(category, i) in categories"
                                :key="i"
                            >
                                {{ category.name }}
                            </router-link>
                        </div>
                        <div class="popup__bg" v-if="drop"></div>
                    </li>

                    <li>
                        <router-link
                            class="list-element"
                            :to="{ name: 'about-me' }"
                        >
                            Su di me
                        </router-link>
                    </li>

                    <li>
                        <router-link
                            class="list-element"
                            :to="{ name: 'contact-me' }"
                        >
                            Contattami
                        </router-link>
                    </li>

                    <li class="close">
                        <font-awesome-icon icon="fa-regular fa-circle-xmark" />
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
    import state from "@/categories";
    import axios from "axios";
    import {GET_CATEGORIES_API} from "@/config";

    export default {
        data() {
            return {
                nav: false,
                drop: false,
                categoriesSetter: [],
            };
        },
        computed: {
            categories() {
                return !state.length ? this.categoriesSetter : state;
            },
        },
        methods: {
            fetchCategories() {
                axios
                    .get(GET_CATEGORIES_API)
                    .then((res) => {
                        try {
                            this.categoriesSetter = [];
                            res.data.forEach((element) => {
                                this.categoriesSetter.push(element);
                            });
                        } catch (e) {
                            console.error(e);
                        }
                    });
            },
            reveal() {
                // console.log('reveal');
                if (window.innerWidth >= 1200) {
                    this.nav = false;
                    this.$parent.$refs.app.style.height = "auto";
                    document.body.style.overflowY = "auto";
                    return;
                }
                if (!this.nav) {
                    this.nav = true;
                    this.$parent.$refs.app.style.height = "100vh";
                    document.body.style.overflowY = "hidden";
                } else {
                    this.nav = false;
                    this.$parent.$refs.app.style.height = "auto";
                    document.body.style.overflowY = "auto";
                }
            },
        },
        mounted() {
            if (!state.length) {
                this.fetchCategories();
            }
        },
    };
</script>

<style lang="scss" scoped>
    .close {
        position: absolute;
        top: 4rem;
        right: 2rem;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;

        @media (min-width: 1200px) {
            display: none;
        }

        &:hover {
            text-decoration: none !important;
        }
    }

    .dropdown {
        position: relative;
        text-align: center;

        .popup__bg {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .drop-items {
            flex-direction: column;
            padding: 1rem;
            border-radius: 0.5rem;
            border: 1px solid #808080;
            margin: 0.5rem 0;
            background-color: rgba(255, 255, 255, 0.1);

            @media (min-width: 1200px) {
                position: absolute;
                top: 100%;
                left: 0;
                height: fit-content;
                width: 200px;
                font-size: 0.75rem;
                padding: 0.5rem;
                background-color: #d9d9d9;
            }

            .drop-item {
                padding: 0.5rem 0;
                z-index: 1000;

                &:not(:last-of-type) {
                    border-bottom: 1px solid #808080;
                }

                @media (min-width: 1200px) {
                    padding: 0.25rem 0;
                    color: #000000 !important;
                }

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }

    .header {
        padding: 0.5rem 2rem;
        color: #fff;
        display: flex;
        align-items: center;
        z-index: 10;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        // bottom: 0;

        .logo {
            font-size: 2rem;
            font-family: "Qwigley", cursive;
            color: currentColor;
            text-decoration: none;

            @media (min-width: 1200px) {
                font-size: 3rem;
            }

            @media (min-width: 992px) {
                font-size: 4rem;
            }
        }

        nav {
            margin-left: auto;

            .burger {
                font-size: 1.5rem;
                border: 1px solid #b1b1b1;
                color: #b1b1b1;
                border-radius: 0.5rem;
                width: 2.5rem;
                height: 2.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &:hover {
                    border-color: #fff;
                    color: #fff;
                }

                @media (min-width: 1200px) {
                    display: none;
                }
            }

            .layover {
                position: absolute;
                top: 0;
                left: 150%;
                // bottom: 0;
                right: 0;
                height: 100vh;
                background-color: rgba(0, 0, 0);
                transition: width 2000ms ease-in-out;

                @media (min-width: 1200px) {
                    position: relative;
                    background-color: transparent;
                    left: 0;
                    height: auto;
                }

                &.animation {
                    // width: 100%;
                    animation-duration: 600ms;
                    animation-fill-mode: forwards;
                    animation-name: reveal;
                }

                @keyframes reveal {
                    from {
                        left: 100%;
                    }

                    to {
                        left: 0;
                    }
                }

                ul {
                    padding-left: 0;
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    z-index: 1;

                    @media (min-width: 1200px) {
                        gap: 1.5rem;
                        display: flex;
                        position: relative;
                        flex-direction: row;
                    }

                    .list-element {
                        padding: 0.75rem 0;
                        display: block;

                        @media (min-width: 1200px) {
                            padding: 0;
                        }
                    }

                    li {
                        cursor: pointer;

                        &:hover:not(.dropdown) {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    a {
        color: #fff;
        text-decoration: none;

        &:hover {
            color: currentColor;
        }
    }
</style>