<template>
    <div id="contact-me">
        <JumbotronVue />

        <MainHeader>
            <div class="container-md">
                <h2 class="text-uppercase mb-sm-5">contact me</h2>
                <p>
                    Per ulteriori informazioni, per vedere il mio catalogo foto
                    completo e per richieste commerciali non esitare a
                    contattarmi tramite il seguente modulo
                </p>
            </div>
        </MainHeader>

        <ContactForm />
    </div>
</template>

<script>
    import JumbotronVue from "@/components/JumbotronLayout.vue";
    import MainHeader from "@/components/MainHeaderLayout.vue";
    import ContactForm from "./components/ContactForm.vue";

    export default {
        methods: {},
        components: {
            ContactForm,
            JumbotronVue,
            MainHeader,
        },
        // metaInfo() {
        //   return {
        //     meta: [
        //       {
        //         name: 'description',
        //         content: 'Hai domande o richieste da fare? Ecco il modulo di contatto'
        //       }
        //     ]
        //   }
        // }
    };
</script>

<style lang="scss" scoped>
    #contact-me .jumbotron {
        background-image: url("@/assets/rp-contact.jpeg");
    }
</style>