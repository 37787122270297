<template>
  <main>
    <section class="luccacomics2023">
      <div class="container">

        <h3 class="mb-5 text-center">
          Cattura l'Evento: Scatta le Emozioni, Una Foto alla Volta! <br>
          Gotta catch 'em all!
        </h3>

        <p>
          È un piacere annunciare che sarò presente anche quest'anno a uno dei più grandi eventi dedicati al mondo dei
          fumetti, dei giochi e della cultura pop.
          La mia passione per la fotografia incontra l'universo straordinario di Lucca Comics and Games, e non vedo
          l'ora di catturare insieme a voi attimi unici e indimenticabili durante l'evento.
        </p>

        <p>
          Se volete immortalare il vostro look cosplay, condividere un sorriso con i vostri eroi preferiti o
          semplicemente avere un ricordo speciale dell'evento, sarò a disposizione per scattare foto coinvolgenti.
          Per rimanere sempre aggiornati sulla mia posizione durante l'evento, vi consiglio di seguirmi sui miei canali
          social ufficiali: vi terrò regolarmente informati tramite Instagram e Facebook. Inoltre, vi invito a
          contattarmi tramite la
          <router-link to="contattami">sezione dedicata</router-link>
          per eventuali richieste o proposte.
        </p>

        <p>
          L'avventura non finisce con la chiusura di Lucca Comics and Games 2023! Una volta conclusa la manifestazione,
          la pagina verrà ulteriormente sviluppata per condividere le migliori foto catturate durante l'evento.
          Troverete una galleria completa di momenti unici, cosplay sorprendenti e dettagli che rendono Lucca Comics
          and Games un'esperienza indimenticabile.
        </p>

        <p>
          Siete pronti a far parte di questa esperienza straordinaria? Venite a trovarmi per scattare foto che
          raccontano la vostra passione per il mondo dei fumetti e dei giochi. Seguitemi sui social per restare
          aggiornati e continuate a seguire questa pagina per non perdere nessuna novità. Lucca Comics and Games 2023
          ci aspetta e insieme cattureremo ricordi che dureranno per sempre. <br>
          Non vedo l'ora di incontrarvi a Lucca!
        </p>

        <p class="text-end">
          <em>
            Roberto Pieraccini.
          </em>
        </p>

      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables";

.luccacomics2023 {
  background-color: $--dark-grey;
  color: #fff;
  padding: 4rem 0;

  @media (min-width: 576px) {
    font-size: 1.25rem;
  }

  a {
    color: #ff0050;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: #ff0070;
      font-style: italic;
      text-decoration: underline;
    }
  }

  //border-top: 5px solid black;
  //-webkit-box-shadow: 0px -30px 3px rgba(0, 0, 0, 0.75);
  //-moz-box-shadow: 0px -30px 3px rgba(0, 0, 0, 0.75);
  //box-shadow: 0px -30px 3px rgba(0, 0, 0, 0.75);

  //.txt-container {
  //  background-color: $--grey;
  //  padding: 2rem;
  //  border-radius: 2rem;
  //
  //  @media (min-width: 1200px) {
  //    font-size: 1.25rem;
  //  }
  //}
}
</style>