import Vue from 'vue'
import App from './App.vue'
import "@fontsource/qwigley"
import "@fontsource/cormorant-sc"
import "@fontsource/wire-one"
import "@fontsource/ubuntu"
import Meta from 'vue-meta'
import router from './router'
import Vue2TouchEvents from 'vue2-touch-events'
Vue.use(Vue2TouchEvents)

import { MotionPlugin } from '@vueuse/motion'
Vue.use(MotionPlugin)

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'


/* add icons to the library */
library.add(faChevronUp)
library.add(faFacebookF)
library.add(faInstagram)
library.add(faEnvelope)
library.add(faBars)
library.add(faCaretDown)
library.add(faCaretUp)
library.add(faChevronLeft)
library.add(faChevronRight)
library.add(faCircleXmark)
library.add(faChevronDown)


/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)


import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(Meta);

Vue.config.productionTip = false

new Vue({
    render: h => h(App),
    router: router
}).$mount('#app')
