<template>
    <footer>
        <div class="container-fluid py-3" id="footer-social">
            <div class="socials">
                <div class="pb-4">
                    <h3 class="text-white">Passa a trovarmi sui miei Social</h3>
                    <div class="d-flex justify-content-center gap-3">
                        <a
                            href="https://www.facebook.com/roberto.pieraccini.52"
                            class="socials-btn fb"
                        >
                            <font-awesome-icon
                                icon="fa-brands fa-facebook-f"
                                class="txt-big"
                            />
                        </a>
                        <a
                            href="https://www.instagram.com/robixpier/"
                            class="socials-btn ig"
                        >
                            <font-awesome-icon
                                icon="fa-brands fa-instagram"
                                class="txt-big"
                            />
                        </a>
                    </div>
                </div>
                <div>
                    <h3 class="text-white">
                        Inviami un'email al mio indirizzo di posta
                    </h3>
                    <div class="d-flex justify-content-center">
                        <a
                            href="mailto:roberto.pieraccini@gmail.com"
                            class="socials-btn mail"
                            target="_blank"
                        >
                            <font-awesome-icon
                                icon="fa-regular fa-envelope"
                                class="txt-big"
                            />
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid" id="footer-main">
            <div
                class="d-flex flex-column flex-md-row justify-content-md-center text-center gap-3 gap-md-0"
            >
                <ul class="ps-0 mb-0 pe-md-4">
                    <li>
                        <h4>Pagine principali</h4>
                    </li>

                    <li>
                        <router-link :to="{ name: 'home' }"> Home</router-link>
                    </li>

<!--                    <li>-->
<!--                        <router-link :to="{ name: 'luccacomics2023' }">-->
<!--                            Lucca Comics and Games 2023-->
<!--                        </router-link>-->
<!--                    </li>-->

                    <li>
                        <router-link :to="{ name: 'about-me' }">
                            Su di me
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{ name: 'contact-me' }">
                            Contattami
                        </router-link>
                    </li>
                </ul>
                <ul class="ps-0 ps-md-4 border-start-md">
                    <li>
                        <h4>Foto per categoria</h4>
                    </li>

                    <li v-for="(category, i) in categories" :key="i">
                        <router-link
                            class="drop-item"
                            :to="{
                                name: 'categories.show',
                                params: {
                                    name: category.name.toLowerCase().split(' ').join('_'),
                                },
                            }"
                        >
                            {{ category.name }}
                        </router-link>
                    </li>
                </ul>
            </div>
            <div
                id="copy"
                class="d-flex align-items-center justify-content-center"
            >
                Copyright &copy; 2022-2024
                <a
                    class="d-flex flex-column align-items-center"
                    href="https://www.francescopieraccini.it/"
                >
                    <img
                        class="block"
                        src="https://www.francescopieraccini.it/my_site/src/img/loghi/light-blue.svg"
                        alt="Logo"
                    />
                    <small id="nome_logo">Francesco Pieraccini</small>
                </a>
                . All Rights Reserved
            </div>
        </div>
    </footer>
</template>

<script>
import state from "@/categories";
import axios from "axios";
import {GET_CATEGORIES_API} from "@/config";

export default {
  data() {
    return {
      categoriesSetter: [],
    };
  },
  computed: {
    categories() {
      return !state.length ? this.categoriesSetter : state;
    },
  },
  methods: {
    fetchCategories() {
      axios
          .get(GET_CATEGORIES_API)
          .then((res) => {
            try {
              this.categoriesSetter = [];
              res.data.forEach((element) => {
                this.categoriesSetter.push(element);
              });
            } catch (e) {
              console.error(e);
              return;
            }
          });
    },
  },
  mounted() {
    if (!state.length) {
      this.fetchCategories();
    }
  },
};
</script>

<style lang="scss" scoped>
    @import "@/style/variables";

    footer {
        .border-start-md {
            @media (min-width: 768px) {
                border-left: 1px solid #ffffff;
            }
        }

        #footer-main {
            background-color: #00000f;
            padding: 1rem 0;

            a {
                color: #eeeeee;
                text-decoration: none;

                &:hover {
                    color: #ffffff;
                    transform: scale(1.3);
                    text-decoration: underline;
                }
            }
        }

        #footer-social {
            background-color: $--black;
        }

        .container-fluid {
            color: #bebebe;

            #copy {
                img {
                    width: 2.5rem;
                    height: 2.5rem;
                    font-size: 1rem;
                }

                small {
                    font-size: 0.5rem;
                    text-decoration: none;
                }
            }

            h3,
            #copy {
                text-align: center;
            }

            h4 {
                font-weight: bold;
                font-style: italic;
            }

            h3,
            h4 {
                color: #ffffff;
            }

            @media (min-width: 992px) {
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
            }
            font-size: 0.6125rem;

            @media (min-width: 576px) {
                font-size: 0.75rem;
            }

            #dev {
                color: #ffffff;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .socials {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;

            .socials-btn {
                width: 3rem;
                height: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #ffffff;
                border-radius: 100%;

                @media (min-width: 576px) {
                    width: 3.5rem;
                    height: 3.5rem;
                }

                &:hover {
                    scale: 1.2;
                    box-shadow: 0 0 5px #ffffff;
                }

                .txt-big {
                    font-size: 2rem;

                    @media (min-width: 576px) {
                        font-size: 2.25rem;
                    }
                }
            }
        }
    }
</style>